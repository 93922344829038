import { mapGetters, mapMutations, mapActions } from 'vuex';
import propertyCardSchema from '@/common/components/schemas/property-card-schema.vue';
import mvtMapStatic from '@/common/components/mvtmapstatic/mvt-mapstatic.vue';
import mvtPlayer from '@/common/components/mvtplayer/mvt-player1.vue';
import motime from '@/common/utilities/motime';
import { processPhotos } from '../../../msp/components/reels-view/photomapper.js';
import SplitTreatments from '@/common/components/split/SplitTreatments.vue';

export default {
    name: 'mvtCardProperty',
    components: {
        mvtMapStatic,
        mvtPlayer,
        propertyCardSchema,
        SplitTreatments,
    },
    inject: ['$eventBus', 'split'],
    props: {
        preload: {
            type: Boolean,
            default: false,
        },

        tracked: {
            type: Boolean,
            default: true,
        },
        data: {
            type: Object,
            required: true,
        },
        showStreetView: {
            type: Boolean,
            default: false,
        },
        openWindow: {
            type: Boolean,
            default: false,
        },
        showShare: {
            type: Boolean,
            default: false,
        },
        showLeadButton: {
            type: Boolean,
            default: false,
        },
        query: {
            type: String,
            default: '',
        },
        showSchema: {
            type: Boolean,
            default: false,
        },
        theme: {
            type: String,
            default: 'style1',
        },
        cardFilter: {
            type: String,
            default: '',
        },
    },
    created() {
        this.staticMapData = {
            width: 320,
            height: 200,
        };
        if (this.options) {
            this.componentName = this.options.type;
        }
    },
    data() {
        return {
            selectedCard: null,
            listingDetails: null,
            propertyDetails: null,
            hovered: false,
            photoRequestFinished: false,
            loading: null,
        };
    },
    methods: {
        ...mapActions('glb', ['updateFavorite', 'getDppPotoByUrl', 'getPropertyInfoById']),
        ...mapMutations('glb', ['addViewedPropertyId']),
        ...mapGetters('glb', ['getComplianceMethod']),
        fetchListingDetails() {
            return new Promise((resolve) => {
                const id = this.property.listingId;
                const url = this.property.path;
                // do not attempt to fetch photos if we already have or if there is only 1 photo
                if ((this.listingDetails && this.listingDetails.id === id) || this.property.photoCount <= 1) {
                    return resolve(null);
                }
                this.getDppPotoByUrl({ url })
                    .then((dppPhoto) => {
                        if (dppPhoto) {
                            let photos = processPhotos(dppPhoto);
                            if (photos.length > 0) {
                                resolve(photos.map((photo) => photo.url));
                            }
                        }
                        resolve(null);
                    })
                    .catch((err) => {
                        resolve(null);
                        console.error(`An error occurred fetching photos for property: ${id}`, err);
                    });
            });
        },
        openDpp(event) {
            event.preventDefault();
            if (this.showLeadButton) {
                let that = this;
                this.selectedCard = this.property;
                setTimeout(() => {
                    that.$eventBus.$emit('send-lead', that.property);
                }, 500);
            } else {
                this.$emit('selectItem', this.property.propertyId, this.property);
                this.addViewedPropertyId(this.property.propertyId);
                setTimeout(() => {
                    window.open(this.cardHref, '_blank');
                }, 50);
            }
        },
        sendEmail(dpp) {
            var dppURL = this.glb.appUrl + dpp.path;
            const propertyId = dpp.propertyId;
            var smsChar = this.glb.OS === 'iOS' ? '%26' : '%3F';
            var sms = `utm_campaign%3Ddppsharing${smsChar}utm_medium%3Dsms${smsChar}utm_source%3Ddpp`;
            if (this.glb.user.id) {
                sms += `${smsChar}utm_term%3D${this.glb.user.id}`;
            } else {
                var anonymousId = $.getCookie('ajs_anonymous_id');
                if (anonymousId) {
                    sms += `${smsChar}utm_term%3D${anonymousId.replace(/"/g, '')}`;
                }
            }
            if (this.glb.user.role) {
                if (['COBROKER_AGENT', 'AGENT'].includes(this.glb.user.role)) {
                    sms += `${smsChar}utm_content%3D${this.glb.user.role}`;
                }
            }
            if (this.glb.influenceAgentId) {
                sms += `${smsChar}agentid%3D${this.glb.influenceAgentId}`;
            }

            // API is fairly new, check if it is supported
            if (this.glb.isMobile && navigator.share) {
                sms = sms.replace(/%3F/g, '%26');
                sms = `${dppURL}%3F${sms}`;
                navigator
                    .share({
                        title: this.molang('dpp.mvtEmail.shareHome'),
                        text: this.molang('dpp.mvtEmail.checkoutHome'),
                        url: unescape(sms),
                    })
                    .then(function () {
                        console.log('Successfully Shared');
                    })
                    .catch(function (e) {
                        console.log(e.message);
                    });
            } else {
                var emailOpt = {
                    propertyId,
                    listingId: dpp.listingId,
                    listingUrl: this.glb.appUrl + dpp.path,
                    propertyData: dpp,
                    showComment: false,
                    comment: 'I am interested in ' + dpp.geo.formatAddress + '.',
                    sms: `${this.glb.geo.formatAddress} ${dppURL}%3F${sms}`,
                    dialogTitle: this.molang('dpp.mvtEmail.shareHome'),
                    hideHotlead: dpp && dpp.isRentals,
                };
                this.$eventBus.$emit('dialog-center', { name: 'mvtSendEmail', trigger: this.$options.name, opt: emailOpt });
            }
        },
        favoriteAction() {
            let dppData = {
                formatMlsInfo: this.property.formatMlsInfo,
                geo: this.property.geo,
                mlsDescription: this.property.mlsDescription,
                mlsLogoSrc: this.property.mlsLogoSrc,
                photoCount: this.property.photoCount,
                tnImgPath: this.property.tnImgPath,
            };
            let options = {
                propertyData: dppData,
                propertyId: this.property.propertyId,
                listingId: this.property.listingId,
                isPrOnly: this.property.isPrOnly,
                isFavorite: !(this.glb.favoriteListings.indexOf(this.property.propertyId) >= 0),
                trigger: this.$options.name,
                lite: true,
            };
            $.setStorage('pendingRequestName', 'addfavorite');
            $.setStorage('favoriteHome', JSON.stringify(options));
            this.updateFavorite(options).then((res) => {
                if (res.status && res.status.code === 0) {
                    if (options.isFavorite) {
                        this.$eventBus.$emit('dialog-center-tip', `<span class="text-bold">Success!</span> <span class="text-medium">Home saved.</span>`);
                    }
                    this.$emit('updateFavListing', options.isFavorite);
                }

                if (options.isFavorite && +$.getStorage('hotleadCount') == 0 && !(this.glb.hotleadInfo.phone || this.glb.user.phone) && !this.glb.rentals) {
                    this.$eventBus.$emit('eventbus-pop-schedule-lead');
                }
            });
        },
        gethouseCardIconText(data) {
            let titleText = `<i class="${data.propertyTypeIcon}" title="${data.propertyTypeDisplayName}"></i>`;
            if (data.isCommingSoonListing && data.houseRealStatus === 'ACTIVE' && data.listDate != '—') {
                titleText += ` ${this.molang('search.card.comingSoon')} ${motime.formatDate(data.listDate, { day: 'numeric', month: 'numeric', year: '2-digit' })}`;
            } else if (data.houseRealStatus === 'ACTIVE') {
                titleText += ' ' + this.molang('search.card.forSale');
            } else if (data.houseRealStatus === 'PENDING') {
                titleText += ' ' + this.molang('search.card.underContract');
            } else if (data.houseRealStatus === 'SOLD') {
                titleText += data.soldDate ? ' Sold on ' + motime.formatDate(data.soldDate) : ' Recently Sold';
            } else if (data.houseRealStatus === 'FOR_RENT') {
                titleText += ' ' + this.molang('search.card.forRent');
            }
            return titleText;
        },
        mouseOverCard() {
            this.hovered = true;
        },
        mouseLeaveCard() {
            this.hovered = false;
        },
        fetchPropertyDetails() {
            if (this.isLight) {
                this.loading = true;
                this.getPropertyInfoById({
                    propertyIds: [this.property.propertyId],
                }).then((res) => {
                    if (res && res[0]) {
                        this.propertyDetails = res[0];
                    }
                    this.loading = null;
                });
            }
        },
        shouldShowMLSLogo() {
            const compliance = this.getComplianceMethod()();
            return compliance[0] && compliance[0].rule.indexOf('hidePropertyCardLogo') === -1;
        },
    },
    computed: {
        ...mapGetters('glb', ['glb', 'viewedListings', 'favoriteListings', 'getSplit']),
        cardHref() {
            return this.property.dppUrl + (this.query ? '?' + this.query : '');
        },
        isViewed() {
            return this.property && !this.property.hideIsViewed && this.viewedListings.indexOf(this.property.propertyId) >= 0;
        },
        isFavorite() {
            return this.favoriteListings.indexOf(this.property.propertyId) !== -1;
        },
        getSubAddress() {
            return `${this.property.geo.city}, ${this.property.geo.state} ${this.property.geo.zipcode}`;
        },
        getHouseCardTitleText() {
            if (this.property.nhsListingType) {
                return this.property.nhsListingType;
            }
            return this.gethouseCardIconText(this.property);
        },
        hoverSlideshowEnabled() {
            return !this.glb.isMobile && this.glb.pageType === 'vmapsearch' && this.theme === 'style1' && this.getSplit('movoto-msp-hover-slideshow-CW-1187');
        },
        isNewConstruction() {
            return (this.cardFilter === 'newConstruction' && this.property.nhsListingType !== 'Ready To Build') || (!this.cardFilter && this.property.nhsListingType === 'New Construction');
        },
        isReadyToBuild() {
            return (!this.cardFilter || this.cardFilter === 'newConstruction') && this.property.nhsListingType === 'Ready To Build';
        },
        isNewTag() {
            return !this.cardFilter && this.property.isNew;
        },
        isSoldTag() {
            return this.property.isSold;
        },
        isOpenTag() {
            return this.cardFilter === 'openHouse' || (!this.cardFilter && this.property.openDate);
        },
        isPriceCutTag() {
            let priceCut = this.property.showPriceChange && !this.property.hideDaysOnMarket && !(this.glb.pageType == 'vmymovoto_savedhomes' && this.property.labelName == 'Active Under Contract');
            return (this.cardFilter && this.cardFilter === 'priceCut') || (!this.cardFilter && priceCut);
        },
        isHotTag() {
            return !this.cardFilter && this.property.isHot;
        },
        isListingByMovoto() {
            return (this.cardFilter && this.cardFilter === 'listedByMovoto') || (!this.cardFilter && this.property.listingByMovoto);
        },
        isCommingSoonListingTag() {
            return this.property.isCommingSoonListing;
        },
        getHouseCardPropertyType() {
            let titleText = '';
            switch (this.property.propertyType) {
                case 'SINGLE_FAMILY_HOUSE':
                    titleText = 'Home';
                    break;
                case 'MULTI_FAMILY':
                    titleText = 'Multi-family';
                    break;
                case 'CONDO':
                    titleText = 'Condo / Townhouse';
                    break;
                case 'LAND':
                    titleText = 'Land';
                    break;
                case 'MOBILE':
                    titleText = 'Mobile';
                    break;
                case 'OTHER':
                default:
                    titleText = this.molang('search.card.other');
            }
            return titleText;
        },
        getHouseCardStatusTextAndClass() {
            let cardStatusObj = {};
            if (this.property.isCommingSoonListing) {
                cardStatusObj = {
                    text: 'Coming soon',
                    className: 'comingsoon',
                };
            } else if (this.property.activeUnderContract) {
                cardStatusObj = {
                    text: 'Active under contract',
                    className: 'activeundercontract',
                };
            } else if (this.property.houseRealStatus === 'ACTIVE') {
                cardStatusObj = {
                    text: 'Active',
                    className: 'active',
                };
            } else if (this.property.houseRealStatus === 'PENDING') {
                cardStatusObj = {
                    text: 'Pending',
                    className: 'pending',
                };
            } else if (this.property.isSold || this.property.houseRealStatus === 'OFF_MARKET' || this.property.houseRealStatus === 'REMOVED') {
                cardStatusObj = {
                    text: 'Off market',
                    className: 'offmarket',
                };
            } else if (this.property.houseRealStatus === 'FOR_RENT') {
                cardStatusObj = {
                    text: 'For rent',
                    className: 'forrent',
                };
            }
            return cardStatusObj;
        },
        isLight() {
            return this.data.light && this.data.propertyId;
        },
        property() {
            return this.propertyDetails || this.data;
        },
    },
    beforeMount() {
        this.fetchPropertyDetails();
    },
};
