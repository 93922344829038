<template>
    <article class="mvt-cardproperty"
        :class="theme"
        @click="openDpp($event)"
        v-ga:clickable="'card'"
        :data-loading="loading"
        :data-link-name="glb.pageType === 'vmapsearch' && property.isActive && property.threeDTourLink ? '3D Card': 'Card'"
        :data-id="property.propertyId"
        :mark-id="'mark0_' + property.geo.lat + '_' + property.geo.lng"
        :data-provider="property.showProvidedBy && property.mlsName ? `${molang('search.card.providedBy')} ${property.mlsName}` : null">
        <propertyCardSchema :data='property' v-if="showSchema"></propertyCardSchema>
        <div class="mvt-cardproperty-photo" :data-mls="property.formatMlsInfo || null">
            <mvtPlayer v-if="hoverSlideshowEnabled && property.photoCount > 1"
            :title="property.geo.formatAddress"
            :alt="property.geo.formatAddress"
            :data="[property.tnImgPath]"
            :lazyload="fetchListingDetails"
            :showControl="false"></mvtPlayer>
            <mvtMapStatic v-else-if="showStreetView && property.photoCount===0"
                type="streetview"
                streetViewDefautType="satellite"
                :lat="property.geo.lat"
                :lng="property.geo.lng"
                :address="property.geo.formatAddress"
                :title="property.geo.formatAddress"
                :alt="property.geo.formatAddress"
                :width="staticMapData.width"
                :height="staticMapData.height"
                :cacheFirst="true">
                </mvtMapStatic>
            <div v-else :data-alt="property.photoCount > 0 ? null : 'Photos coming soon'" >
                <img :loading="preload ? null : 'lazy'" :fetchpriority="preload ? 'high' : null" :src="property.tnImgPath" :alt="property.photoCount > 0 ? property.geo.formatAddress : 'Photos coming soon'" />
            </div>
            <button class="btn-3d" type="button" v-ga:clickable link-text="Video"  @click.stop="openDpp($event)" v-if="property.isVideoTour">
                <img loading="lazy" :src="glb.cdnUrl + 'vimages/videotour.svg'">
            </button>
            <SplitTreatments :names="['movoto-msp-3Dtour-pill-CW-2197']" v-else-if="glb.pageType === 'vmapsearch' && property.isActive && property.threeDTourLink">
                <template #default="{ treatments, isReady }">
                    <button class="btn-3d " type="button" v-ga:clickable data-link-name="3D"  @click.stop="openDpp($event)" v-if="isReady && (treatments['movoto-msp-3Dtour-pill-CW-2197'] === 'on')">
                        <img loading="lazy" :src="glb.cdnUrl + 'vimages/home_animate.svg'">
                    </button>
                 </template>
            </SplitTreatments>
        </div>
        <div class="mvt-cardproperty-info">
            <div>
                <a :href="cardHref" target="_blank" @click.stop="openDpp($event)" tabindex="-1">
                    <address><span>{{property.geo.address}}</span><span>{{getSubAddress}}</span></address>
                </a>
                <sub v-if="getHouseCardTitleText" v-html="getHouseCardTitleText"></sub>
                <img v-if="property.mlsLogoSrc && shouldShowMLSLogo()" loading="lazy" class="img-mls" :alt="property.mlsDescription" :src="property.mlsLogoSrc"/>
            </div>
            <ul :data-viewed="isViewed">
                <li class="price">
                    {{ $filters.formatPrice(property.price) }}<abbr title="Monthly Rent" v-if="property.isRentals">/Mo</abbr><abbr title="Estimate Vaule" v-if="property.isEstPrice"> Est.</abbr>
                </li>
                <template v-if="!property.isLand">
                    <li>
                        {{property.bed || '—'}}<abbr title="Bedroom">Bd</abbr>
                    </li>
                    <li>
                        {{property.bath || '—'}}<abbr title="Bathroom">Ba</abbr>
                    </li>
                </template>
                <li v-if="property.area">
                    {{$filters.formatArea(property.area, property.isLand)}}<abbr :title="property.areaUnit">{{property.areaUnit}}</abbr>
                </li>
                <template v-if="!property.isRentals">
                    <li class="sub" v-if="(property.isLand || property.isCondo) && property.pricePerArea">
                        {{$filters.formatPricePerArea(property.pricePerArea, property.isLand)}}
                    </li>
                    <li class="sub" v-else-if="property.lotSize">
                        {{$filters.formatFriendlyLotSize(property.lotSize)}}
                    </li>
                </template>
            </ul>
        </div>
        <div class="tag-panel">
            <div class="tag-group left" tabindex="-1">
                <span class="tag custom new-construction-tag" v-if="isNewConstruction"><span>New Construction</span></span>
                <span class="tag custom ready-to-build-tag" v-else-if="isReadyToBuild"><span>Ready To Build</span></span>
                <span class="tag custom new-tag" v-else-if="isNewTag"><span>{{ molang('search.card.newAgo') }}</span><span>{{ property.createTimeDisplay.replace('Minutes', 'Min') }}</span></span>
                <span class="tag custom sold-tag" v-else-if="isSoldTag"><span>{{ property.houseRealStatusDisplay }}</span><span v-if="property.showSoldDate">{{ $filters.formatDate(property.soldDate, {year: '2-digit'}) }}</span></span>
                <span class="tag custom open-tag" v-else-if="isOpenTag"><span>{{molang('search.card.open')}}</span><span>{{ property.openDateV1 }}</span></span>
                <span class="tag custom pricecut-tag" v-else-if="isPriceCutTag">
                    <span :class="property.isPriceReduced?'price-reduced':'price-increased'">
                        <i :class="{'icon-arrow-down-circle-filled': property.isPriceReduced,'icon-send-ios': !property.isPriceReduced}"></i>
                        {{$filters.formatFriendlyPrice(property.priceChange)}}
                    </span>
                </span>
                <span class="tag custom hot-tag" v-else-if="isHotTag">
                    <span>
                        <img :src="glb.cdnUrl + 'vimages/fire-filled.svg'">
                        HOT
                    </span>
                </span>
                <span class="tag custom listingby-movoto-tag" v-else-if="isListingByMovoto"><span>{{ molang('dpp.propertyNearby.listedBy') }}</span></span>
            </div>
            <div class="tag-group right">
                <button v-if="showShare" type="button" aria-label="Share Property"  @click="sendEmail(data)" class="share circle small bg-white " ><i class="icon-share-ios"></i></button>
                <span v-if="showLeadButton" aria-label="select Property" class="circle small" :class="selectedCard ? 'bg-green' : 'bg-white'"><i :class="{'icon-check': selectedCard}"></i></span>
                <button v-else type="button" aria-label="Favorite Property" @click.stop="favoriteAction" class="favorite circle small bg-white" title="Save"><i class="icon-heart-o" :class="{'active':isFavorite}"></i></button>
            </div>
        </div>
    </article>

</template>
<script src="./mvt-cardproperty.js"></script>
