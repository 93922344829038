import { mapGetters } from 'vuex';

export default {
    name: 'mvtPlayer',
    inject: ['$eventBus'],
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        //function to get images []
        lazyload: {
            type: Function,
            default: null,
        },
        startIndex: {
            type: Number,
            default: 0,
        },
        autoPlay: {
            type: Boolean,
            default: false,
        },
        showProcess: {
            type: Boolean,
            default: false,
        },
        showControl: {
            type: Boolean,
            default: true,
        },
        loop: {
            type: Boolean,
            default: true,
        },
        revert: {
            type: Boolean,
            default: false,
        },
        alt: {
            type: String,
            default: 'Slideshow Image',
        },
    },
    data() {
        return {
            _data: [],
            isRevert: false,
            index: 0,
            isPaused: true,
            nextImage: 'img2',
            img1Src: '',
            img2Src: '',
            timer: null,
            timer2: null,
            loading: 0,
        };
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        preloadImg() {
            return this._data[this.preIndex];
        },
        preIndex() {
            //preload image
            if (this._data.length <= 2) return 0;
            let preIndex = this.index;
            if (this.isRevert) {
                preIndex = this.index - 2;
                if (preIndex < 0) {
                    preIndex = this._data.length - 2;
                }
            } else {
                preIndex = this.index + 2;
                if (preIndex >= this._data.length) {
                    preIndex = 1;
                }
            }
            return preIndex;
        },
    },
    methods: {
        _updateNextImage() {
            let nextImageIndex;
            if (this.isRevert) {
                nextImageIndex = this.index - 1;
                if (this.index === 0) {
                    nextImageIndex = this._data.length - 1;
                }
            } else {
                nextImageIndex = this.index + 1;
                if (this.index === this._data.length - 1) {
                    nextImageIndex = 0;
                }
            }
            if (this.nextImage === 'img1') {
                this.img1Src = this._data[nextImageIndex];
            } else if (this.nextImage === 'img2') {
                this.img2Src = this._data[nextImageIndex];
            }
        },
        _updateCurrentImage() {
            if (this.nextImage === 'img1') {
                this.img2Src = this._data[this.index];
            } else if (this.nextImage === 'img2') {
                this.img1Src = this._data[this.index];
            }
        },
        _update(force) {
            if (force) {
                this.$el.classList.remove('playing');
            }
            this._updateNextImage();

            if (force) {
                this._updateCurrentImage();
                if (this.timer) {
                    clearTimeout(this.timer);
                }
                this.timer = setTimeout(() => {
                    this.$el.classList.add('playing');
                    this.isPaused = false;
                }, 500);
            }
            this.$emit('update', this.index);
        },
        replay() {
            this.goTo(0);
            this.$eventBus && this.$eventBus.$emit('ga', { ec: 'photo_player_impressions', en: 'impressions', el: 'play' });
        },
        goTo(i) {
            if (i >= 0) {
                if (i > this._data.length - 1) {
                    this.index = this._data.length - 1;
                } else {
                    this.index = i;
                }
            } else {
                this.index = 0;
            }
            this._update(true);
        },
        next(force) {
            if (force) {
                this.isRevert = false;
            }
            if (this.index == this._data.length - 1) {
                this.index = 0;
            } else {
                this.index++;
            }
            this._update(force);
        },
        prev(force) {
            if (force) {
                this.isRevert = true;
            }
            if (this.index == 0) {
                this.index = this._data.length - 1;
            } else {
                this.index--;
            }
            this._update(force);
        },
        _play() {
            if (this.$el.classList.contains('playing')) return false;
            this.isPaused = false;
            this.$el.classList.add('playing');
            return true;
        },
        play() {
            let successed = this._play();
            if (!successed) return;
            this.$emit('play', this.index);
            this.$eventBus && this.$eventBus.$emit('ga', { ec: 'photo_player_impressions', en: 'impressions', el: 'play' });
        },
        _pause() {
            if (!this.$el.classList.contains('playing')) return false;
            this.isPaused = true;
            this.$el.classList.remove('playing');
            return true;
        },
        pause() {
            let successed = this._pause();
            if (!successed) return;
            this.$emit('pause', this.index);
            this.$eventBus && this.$eventBus.$emit('ga', { ec: 'photo_player_impressions', en: 'impressions', el: 'pause' });
        },
        _end() {
            this.isPaused = true;
            this.$el.classList.remove('playing');
            this.$emit('end', this.index);
        },
        toggle() {
            if (this.isPaused) {
                this.play();
            } else {
                this.pause();
            }
        },
        _switch() {
            if (this.isRevert) {
                this.prev();
            } else {
                this.next();
            }
        },
        setNextImage(img) {
            this.nextImage = img;
            if (img === 'img1') {
                this.$refs.img2.classList.add('active');
                this.$refs.img1.classList.remove('active');
            } else {
                this.$refs.img1.classList.add('active');
                this.$refs.img2.classList.remove('active');
            }
        },
        onFirstImgAnimationEnd() {
            if (!this.loop && this.index === this._data.length - 1) {
                this._end();
                return;
            }
            this.setNextImage('img2');
            this._switch();
        },
        onLastImgAnimationEnd() {
            if (!this.loop && this.index === this._data.length - 1) {
                this._end();
                return;
            }
            this.setNextImage('img1');
            this._switch();
        },
        initialize() {
            if (!this._data.length) return;
            if (this.lazyload) {
                //only call lazyload if user hover on the photo more than 1s
                this.$el.addEventListener('mouseover', () => {
                    clearTimeout(this.timer2);
                    if (this.loading === 3) {
                        this._pause();
                        return;
                    }
                    if (this.loading === 1) {
                        return;
                    }
                    if (this.loading === 2) {
                        this._play();
                        return;
                    }
                    this.timer2 = setTimeout(() => {
                        this._play();
                        this.loading = 1;
                        this.lazyload()
                            .then((data) => {
                                if (data) {
                                    this._data = data;
                                    this.img2Src = this._data[1];
                                    this.loading = 2;
                                } else {
                                    this.loading = 3;
                                }
                            })
                            .catch(() => {
                                setTimeout(() => {
                                    this.loading = 3;
                                }, 200);
                            });
                    }, 200);
                });
                this.$el.addEventListener('mouseout', () => {
                    this._pause();
                    clearTimeout(this.timer2);
                });
            } else {
                if (this.autoPlay) {
                    this.play();
                }
            }
            this.$emit('ready');
        },
        clean() {
            clearTimeout(this.timer);
            this.nextImage = null;
            this.isPaused = true;
        },
    },
    created() {
        this._data = this.data;
        this.isRevert = this.revert;
        if (this.startIndex > 0) {
            this.index = Math.min(this._data.length - 1, this.startIndex);
        }
        this._updateCurrentImage();
        this._updateNextImage();
    },
    mounted() {
        this.initialize();
    },
    beforeUnmount() {
        this.clean();
    },
};
